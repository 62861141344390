<template>
  <div class="home">

    <div class="button-row">
      <button @click="create" class="big">Add another +</button>
      <button v-if="passwords.length" @click="regenerateAll" class="big info">Regenerate all</button>
    </div>

    <hr />
    <div class="length-row">
      <div>
        <label for="passwordLength">Length: </label><input v-model="passwordLength" min="1" :max="max" id="passwordLength" type="range" />
      </div>
      <div>
        <input v-model="passwordLength" type="number" :max="max"/>
      </div>
    </div>
    <hr />
    <div class="button-row">
      <button :class="activeIf('complex')" @click="setChars('complex')">Complex</button>
      <button :class="activeIf('alphanumeric')" @click="setChars('alphanumeric')">Alphanumeric</button>
      <button :class="activeIf('letters')" @click="setChars('letters')">Letters</button>
      <button :class="activeIf('numbers')" @click="setChars('numbers')">Numbers</button>
      <button :class="activeIf('custom')" @click="setChars('custom')">Custom</button>
      <button :class="{ 'active' : showcase }" @click="toggleCase">Case...</button>
      <button :class="activeIf('custom')" @click="setChars('words')">Words</button>
    </div>
    <div v-if="showcase" class="option">
      Case:
      <label for="radio-UPPER">
        <input type="radio" v-model="textcase" id="radio-UPPER" value="UPPER"/> 
        Uppercase
      </label>
      <label for="radio-LOWER">
        <input type="radio" v-model="textcase" id="radio-LOWER" value="LOWER"/> 
        Lowercase
      </label>
      <label for="radio-NONE">
        <input type="radio" v-model="textcase" id="radio-NONE" value="NONE"/>
        Both
      </label>
    </div>

    <div v-if="key == 'custom'" class="option">
      <textarea rows="2" type="text" v-model="custom"></textarea>
    </div>

    <hr />

    <ol v-if="passwords.length">
      <li v-for="(password, index) in passwords">
        <div>
          <input type="text" :value="password"
          :ref="'input'+index"
          @focus="$event.target.select()"
          @click="$event.target.select()">
          <button @click="remake(index)">Regenerate</button>
          <button @click="remove(index)" class="bad">Remove</button>
          <button @click="copy(index, $event)" class="info">Copy</button>
        </div>
      </li>
    </ol>
    <div v-else>
      <p>Please generate a password by clicking above!</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'home',
    data(){
      return {
        passwords : [],
        initialPasswordCount : 10,
        passwordLength : 30, 
        patterns : {
          complex : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@£$%^&*()_+-=09876543231;:/|[]{}?',
          alphanumeric : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz09876543231',
          letters : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
          numbers : '1234567890',
        },
        key : 'complex',
        custom : 'djave',
        textcase : 'NONE',
        showcase : false
      }
    },
    computed : {
      max(){
        if(this.key == 'words'){
          return 6;
        }
        return 50;
      },
      chars(){
        if(this.key == 'custom'){
          return this.custom;
        }
        return this.patterns[this.key];
      }
    },
    'methods' : {
      toggleCase(){
        this.showcase = ! this.showcase;
      },
      activeIf(key){
        return key == this.key ? 'active' : '';
      },
      copy(index, e){
        let input = this.$refs['input'+index][0].select();
        document.execCommand('copy');
        e.target.innerHTML = "Copied!";
        setTimeout(function(){
          e.target.innerHTML = "Copy";
        }, 800)
      },
      setChars(key){
        this.key = key;
        if(this.passwordLength > this.max){
          this.passwordLength = this.max;
        }
      },
      getLetter: function(chars){
        let letter = chars[Math.floor(Math.random() * chars.length)];
        if(this.textcase == 'UPPER'){
          letter = letter.toUpperCase();
        }
        if(this.textcase == 'LOWER'){
          letter = letter.toLowerCase();
        }
        return letter;
      },
      async getWord(){
        let wi = Math.floor(Math.random() * 178187);
        let file = '/data/words-' + (Math.floor(wi / 1000) + 1) + '.json';
        let fileIndex = wi % 1000;
        let response = await fetch(file);
        let data = await response.json();
        return data[fileIndex];
      },
      async generateWordPassword(){
        let promises = [];
        for(var i = 0; i < this.passwordLength; i ++){
          promises.push(this.getWord());
        }
        let words = await Promise.all(promises)
        return words.join("_");
      },
      async generate(){
        if(this.key == 'words'){
          return await this.generateWordPassword();;
        }
        var chars = this.chars.split('');
        var password = '';
        for(var i = 0; i < this.passwordLength; i ++ ){
          password += this.getLetter(chars);
        }
        return password;
      },
      async remake(index){
        var newPass = await this.generate();
        this.passwords.splice(index, 1, newPass);
      },
      async create(){
        let password = await this.generate()
        this.passwords.push(password);
      },
      remove(index){
        this.passwords.splice(index, 1);
      },
      regenerateAll(){
        for(var j = 0; j < this.passwords.length; j ++){
          this.remake(j);
        }
      },
      generateInitial(){
        setTimeout(() => {
          if(this.initialPasswordCount > 0){
            this.create();
            this.initialPasswordCount --;
            this.generateInitial();
          }
        }, 50)
      }
    },
    'mounted'(){
      var self = this;
      this.generateInitial();
    }
  }
</script>
<style type="text/css">
button + button,
input + input{
  margin-left: 10px;
}
</style>